import { HttpService } from './base/HttpService';
import {
    ConnectionTypes,
    GetAnswerListResponse,
    GetConnectionListResponse,
    GetSingleConnectionResponse,
    PostAnswerSchema,
    SwaggerResponse,
} from '../api/ApiClientGenerated';

class ConnectionService extends HttpService {
    addConnectionAnswersConfiguration = (connectionId: number, body: PostAnswerSchema[]) =>
        this.Client.answersPOST(connectionId, body);

    getAnswers = (connection_id: number): Promise<SwaggerResponse<GetAnswerListResponse>> =>
        this.Client.answersGET(connection_id);

    getConnectionDetails = (connectionId: number): Promise<SwaggerResponse<GetSingleConnectionResponse>> =>
        this.Client.connection(connectionId);

    getConnectionsList = (connectionType: ConnectionTypes): Promise<SwaggerResponse<GetConnectionListResponse>> => {
        return this.Client.connections(connectionType);
    };

    handleDisconnect = (id: number) => {
        //TODO: implement when endpoint exists
        console.log('in handle disconnect: ', id);
    };
}

export const connectionService = new ConnectionService();
