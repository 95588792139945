import { HttpService } from './base/HttpService';
import { GetSingleUserResponse, SwaggerResponse } from '../api/ApiClientGenerated';

class UserService extends HttpService {
    getUser = (): Promise<SwaggerResponse<GetSingleUserResponse>> => {
        return this.Client.user();
    };
}

export const userService = new UserService();
