import { HttpService } from './base/HttpService';
import {
    customizationConfiguration,
    IPostSettingsResponse,
    IPostSettingsSchema,
    ISettings,
    LanguageModelOption,
    llmOptionsConfiguration,
} from '../redux/slices/settingsSlice';
import { SwaggerResponse } from '../api/ApiClientGenerated';

class SettingsService extends HttpService {
    getSettings = () => {
        //TODO: replace when endpoint exists
        const pretendSettings: ISettings = {
            customization: {
                iconUrl: undefined,
                botName: 'George',
            },
            llmOptions: {
                isCustomTemperatureEnabled: false,
                customTemperature: null,
                isThinkHarderEnabled: false,
                systemPrompts: undefined,
                defaultLanguageModel: LanguageModelOption.ChatGpt3Point5,
            },
        };
        const pretendResponse: SwaggerResponse<ISettings> = {
            status: 200,
            headers: {
                'Content-Type': 'application/json',
            },
            result: pretendSettings,
        };
        return Promise.resolve(pretendResponse);
    };

    updateSettings = (llmOptions: llmOptionsConfiguration, customization: customizationConfiguration) => {
        console.log('updating settings:', llmOptions, customization);
        //TODO: replace when endpoint exists
    };
}

export const settingsService = new SettingsService();
