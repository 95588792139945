import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPostAnswerSchema, Question, UsedFor } from '../api/ApiClientGenerated';
import { submitAnswerConfigurationAction } from '../redux/slices/connectionSlice';
import { RootState } from '../redux/store';

const segregateQuestions = (questions: Question[]) => {
    const sortedQuestions = [...questions].sort((a, b) => a.order - b.order);
    const indexQuestions = sortedQuestions.filter((question) => question.usedFor === UsedFor.INDEX);
    const regularQuestions = sortedQuestions.filter((question) => question.usedFor !== UsedFor.INDEX);
    return { indexQuestions, regularQuestions };
};

export const useAnswerUpdates = () => {
    const { value } = useSelector((state: RootState) => state.connection.connectionDetails);
    const dispatch = useDispatch();
    const [answerUpdates, setAnswerUpdates] = useState<IPostAnswerSchema[]>([]);
    const [areAllIndexQuestionsAnswered, setAreAllIndexQuestionsAnswered] = useState(false);
    const sortedQuestions = [...(value?.questions ?? [])].sort((a, b) => a.order - b.order);
    const { indexQuestions, regularQuestions } = segregateQuestions(value?.questions ?? []);

    useEffect(() => {
        const allQuestionsAnswered = indexQuestions.every((question) =>
            answerUpdates.some((update) => update.question_id === question.id && update.value !== ''),
        );
        setAreAllIndexQuestionsAnswered(allQuestionsAnswered);
    }, [answerUpdates]);

    const onChange = ({
        answerId,
        questionId,
        newValue,
    }: {
        answerId?: number;
        questionId: number;
        newValue: string;
    }) => {
        setAnswerUpdates((prevUpdates) => {
            const existingUpdateIndex = prevUpdates.findIndex((update) => update.question_id === questionId);
            const updatedAnswer = { answerId, question_id: questionId, value: newValue };
            if (existingUpdateIndex !== -1) {
                const newUpdates = [...prevUpdates];
                newUpdates[existingUpdateIndex] = updatedAnswer;
                return newUpdates;
            } else {
                return [...prevUpdates, updatedAnswer];
            }
        });
    };

    const onSubmit = () => {
        dispatch(submitAnswerConfigurationAction(answerUpdates));
        setAnswerUpdates([]);
    };

    return {
        answerUpdates,
        areAllIndexQuestionsAnswered,
        indexQuestions,
        regularQuestions,
        onChange,
        onSubmit,
        sortedQuestions,
    };
};
