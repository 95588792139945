import React, { createContext, useContext, useMemo, useState } from 'react';
import auth0, { WebAuth } from 'auth0-js';

type Auth0ProviderWrapperProps = {
    children: React.ReactNode;
};

export type AuthState = {
    accessToken: string;
    idToken: string;
    expiresAt: number;
    isAuthenticated: boolean;
    isLoadingEmailLogin: boolean;
    isLoadingGoogleLogin: boolean;
    expiresIn: number;
};

export const initialAuthState: AuthState = {
    accessToken: '',
    idToken: '',
    expiresAt: 0,
    isAuthenticated: false,
    isLoadingEmailLogin: false,
    isLoadingGoogleLogin: false,
    expiresIn: 0,
};

export type AuthContext = {
    auth0: WebAuth;
    authState: AuthState;
    setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
};

const Auth0Context = createContext<AuthContext | undefined>(undefined);

export const useAuth0Context = () => {
    const context = useContext(Auth0Context);
    if (!context) {
        throw new Error('useAuth0Context must be used within a Auth0Provider');
    }
    return context;
};

export const Auth0Provider = ({ children }: Auth0ProviderWrapperProps) => {
    const [authState, setAuthState] = useState<AuthState>(initialAuthState);

    const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
    const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';

    if (!domain || !clientID) {
        console.error('Environment variables for Auth0 are missing');
    }

    const auth = useMemo(
        () =>
            new auth0.WebAuth({
                domain: domain,
                clientID: clientID,
                redirectUri: `${window.location.origin}/auth/callback`,
                responseType: 'token',
            }),
        [domain, clientID],
    );

    const authContext: AuthContext = {
        auth0: auth,
        authState,
        setAuthState,
    };

    return <Auth0Context.Provider value={authContext}>{children}</Auth0Context.Provider>;
};
