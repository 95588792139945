import React from 'react';
import { StyledLink } from '../../shared/styledComponents/StyledComponents';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { toReadableText } from '../../../utils/stringUtils';
import { NavButtons } from '../../shared/styledComponents/StyledButtons';

interface IconType {
    normal: React.ReactElement;
    active: React.ReactElement;
}

const getIcon = (name: string, isActive: boolean = false) => {
    const iconName = isActive ? `${name}-White` : name;
    const iconPath = require(`../../../assets/svg/${iconName}.svg`);
    return <img src={iconPath} alt={name} />;
};

const icons: Record<string, IconType> = {
    knowledgeConnections: {
        normal: getIcon('BrainIcon'),
        active: getIcon('BrainIcon', true),
    },
    actionConnections: {
        normal: getIcon('LightningIcon'),
        active: getIcon('LightningIcon', true),
    },
    personality: {
        normal: getIcon('TextBubbleIcon'),
        active: getIcon('TextBubbleIcon', true),
    },
    accessControl: {
        normal: getIcon('KeyholeIcon'),
        active: getIcon('KeyholeIcon', true),
    },
    generalSettings: {
        normal: getIcon('SettingsGearIcon'),
        active: getIcon('SettingsGearIcon', true),
    },
};

type Props = {
    keyName: string;
    route: string;
    isActive: boolean;
    toggleActive: (keyName: string) => void;
};

export const RouteItem = ({ keyName, route, isActive, toggleActive }: Props) => {
    return (
        <StyledLink to={route}>
            <ListItem disablePadding>
                <NavButtons onClick={() => toggleActive(keyName)} selected={isActive}>
                    <ListItemIcon>{isActive ? icons[keyName]?.active : icons[keyName]?.normal}</ListItemIcon>
                    <ListItemText>
                        <Typography variant={'h3'}>{toReadableText(keyName)}</Typography>
                    </ListItemText>
                </NavButtons>
            </ListItem>
        </StyledLink>
    );
};
