import { SelectChangeEvent, Stack } from '@mui/material';
import { SectionHeader } from '../../../shared/SectionHeader';
import React, { ChangeEvent, useCallback } from 'react';
import { ThinkHarder } from './ThinkHarder';
import { DefaultLanguageModel } from './DefaultLanguageModel';
import { CustomTemperature } from './CustomTemperature';
import { SystemPrompts } from './SystemPrompts';
import {
    LanguageModelOption,
    llmOptionsConfiguration,
    setLlmSettingsAction,
} from '../../../../redux/slices/settingsSlice';
import * as _ from 'lodash';
import { useSettings } from '../../../../hooks/useSettings';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

// Type guard that checks whether a string value is a valid LanguageModelOption value
function isLanguageModelOption(value: string): value is LanguageModelOption {
    return Object.values(LanguageModelOption).includes(value as LanguageModelOption);
}

const DEBOUNCE_TIME = 2000;

export const LLMOptionsSection = () => {
    const { llmOptions } = useSelector((state: RootState) => state.settings);
    const dispatch = useDispatch();
    const { updateLlm } = useSettings();

    const debouncedSaveSystemPrompts = useCallback(
        _.debounce((newSystemPrompts: string) => {
            updateLlm('systemPrompts', newSystemPrompts);
        }, DEBOUNCE_TIME),
        [updateLlm],
    );

    const handleDefaultLanguageModelChange = useCallback(
        (event: SelectChangeEvent) => {
            const value = event.target.value;
            if (isLanguageModelOption(value)) {
                updateLlm('defaultLanguageModel', value as LanguageModelOption);
            }
        },
        [updateLlm],
    );

    const handleSliderChange = useCallback(
        (event: Event, newValue: number | number[]) => {
            const value = Array.isArray(newValue) ? newValue[0] : newValue;
            const updatedSettings: llmOptionsConfiguration = {
                ...llmOptions,
                isCustomTemperatureEnabled: true,
                customTemperature: value,
            };
            dispatch(setLlmSettingsAction(updatedSettings));
        },
        [updateLlm],
    );

    // Handler for enabling/disabling custom temperature
    const toggleTemperature = useCallback(() => {
        const newState = !llmOptions?.isCustomTemperatureEnabled;
        const updatedSettings: llmOptionsConfiguration = {
            ...llmOptions,
            isCustomTemperatureEnabled: newState,
            customTemperature: newState ? 5 : null,
        };
        dispatch(setLlmSettingsAction(updatedSettings));
    }, [llmOptions.isCustomTemperatureEnabled, updateLlm]);

    // Handles the change in text input for system prompts
    const handleChangeSystemPrompts = (event: ChangeEvent<HTMLInputElement>) => {
        const currentSystemPrompts = event.target.value;
        debouncedSaveSystemPrompts(currentSystemPrompts);
    };

    return (
        <Stack spacing={2}>
            <SectionHeader title={'LLM Options'} />
            <ThinkHarder
                isThinkHarderEnabled={llmOptions.isThinkHarderEnabled ?? false}
                onClick={() => updateLlm('isThinkHarderEnabled', !llmOptions.isThinkHarderEnabled)}
            />
            <DefaultLanguageModel
                defaultLanguageModel={llmOptions.defaultLanguageModel ?? LanguageModelOption.ChatGpt3Point5}
                onChange={handleDefaultLanguageModelChange}
            />
            <CustomTemperature
                customTemperature={llmOptions.customTemperature ?? null}
                isCustomTemperatureEnabled={llmOptions.isCustomTemperatureEnabled ?? false}
                onSliderChange={handleSliderChange}
                onToggle={toggleTemperature}
            />
            <SystemPrompts systemPrompts={llmOptions.systemPrompts} onChangeText={handleChangeSystemPrompts} />
        </Stack>
    );
};
