import React, { useEffect } from 'react';
import { useAuth } from './useAuth';
import { appRoutes } from '../router/AppRouter';

type Props = {
    children: React.ReactNode;
};

export const EnsureAuth = ({ children }: Props) => {
    const { logout } = useAuth();

    useEffect(() => {
        window.addEventListener('beforeunload', handleLogout);
        return () => {
            window.removeEventListener('beforeunload', handleLogout);
        };
    }, []);

    const handleLogout = () => {
        const onLoginScreen = window.location.pathname === appRoutes.login;
        const rememberAccount = localStorage.getItem('rememberAccount');
        if (rememberAccount !== 'true' && !onLoginScreen) logout();
    };

    return <>{children}</>;
};
