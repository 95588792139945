import React, { ChangeEvent, MutableRefObject } from 'react';

type Props = {
    onFileChange: (file: File) => void;
    fileInputRef: MutableRefObject<HTMLInputElement | null>;
};
export const FileInput = ({ onFileChange, fileInputRef }: Props) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            onFileChange(file);
        }
    };

    return (
        <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".jpg,.jpeg,.png,.gif"
            onChange={handleChange}
            aria-label="File input for uploading an icon"
        />
    );
};
