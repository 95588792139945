import { Button, ButtonProps, ListItemButton, styled } from '@mui/material';
import { appColors } from '../../../themes/AppTheme';

interface StyledToggleButtonProps {
    connected?: boolean;
    onClick: () => void;
}

export const sharedBorderStyles = {
    border: 'solid 1.5px',
};

// Function to generate toggle button styles
const generateToggleButtonStyles = (connected?: boolean) => ({
    ...sharedBorderStyles,
    color: connected ? appColors.orangutan : appColors.albinoApe,
    backgroundColor: connected ? 'none' : appColors.simianSage,
    borderColor: connected ? appColors.orangutan : appColors.simianSage,
});

export const NavButtons = styled(ListItemButton)({
    '&.Mui-selected': {
        backgroundColor: appColors.orangutan,
        color: appColors.albinoApe,
    },
});

// Styled Toggle Button
export const StyledToggleButton = styled(
    ({ connected = true, ...otherProps }: StyledToggleButtonProps & ButtonProps) => <Button {...otherProps}></Button>,
)(({ connected }: StyledToggleButtonProps) => generateToggleButtonStyles(connected));

export const StyledSaveButton = styled(Button)({
    backgroundColor: appColors.orangutan,
    color: appColors.albinoApe,
    borderColor: appColors.orangutan,
});

export const DeleteOverlay = styled(Button)(() => ({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: appColors.albinoApe,
    fontSize: '1.2rem',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
}));
