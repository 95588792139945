import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { useAuth } from '../useAuth';

type Props = {
    open: boolean;
    onClose: () => void;
};

export const ForgotPasswordDialog = ({ open, onClose }: Props) => {
    const [email, setEmail] = useState('');
    const { resetPassword } = useAuth();

    const handleResetPassword = () => {
        resetPassword(email);
        setEmail('');
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                setEmail('');
                onClose();
            }}
            fullWidth
        >
            <DialogTitle>
                Reset Password
                <Typography marginTop={1}>We'll send you a link to reset your password</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField
                        label={'Email'}
                        fullWidth
                        variant={'filled'}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <Button variant={'contained'} onClick={handleResetPassword}>
                        Send email
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
