import { Stack, Switch } from '@mui/material';
import { TitleSubtitle } from '../../../shared/TitleSubtitle';
import React from 'react';

type Props = {
    isThinkHarderEnabled: boolean;
    onClick: () => void;
};
export const ThinkHarder = ({ isThinkHarderEnabled, onClick }: Props) => {
    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <TitleSubtitle
                title={'Enable "Think Harder'}
                subtitle={'If prompted to “think harder”, George will switch to using a more powerful language model. '}
            />
            <Switch checked={isThinkHarderEnabled} onChange={onClick} />
        </Stack>
    );
};
