import { Box, Stack } from '@mui/material';
import { QuestionFactory } from './QuestionFactory';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import React, { useEffect } from 'react';
import { SectionHeader } from '../../shared/SectionHeader';
import { getAnswerConfigurationAction } from '../../../redux/slices/connectionSlice';
import { TitleSubtitleProps } from '../../shared/Types';
import { LoadingProgress } from '../../shared/LoadingProgress';
import { useAnswerUpdates } from '../../../hooks/useAnswerUpdates';
import { ConnectionStatusTypes } from '../../../api/ApiClientGenerated';
import { StyledSaveButton } from '../../shared/styledComponents/StyledButtons';

export const QuestionSection = ({ title, subtitle }: TitleSubtitleProps) => {
    const dispatch = useDispatch();
    const {
        value: connectionDetailsValue,
        loaded,
        loading,
    } = useSelector((state: RootState) => state.connection.connectionDetails);
    const { regularQuestions, onChange, onSubmit } = useAnswerUpdates();

    useEffect(() => {
        if (!connectionDetailsValue?.id) return;
        dispatch(getAnswerConfigurationAction(connectionDetailsValue.id));
    }, [connectionDetailsValue]);

    if (!connectionDetailsValue?.questions) return <></>;

    const isSaveButtonDisabled =
        connectionDetailsValue.connection_status_type === ConnectionStatusTypes.INCOMPLETE ||
        connectionDetailsValue.connection_status_type === null;

    if (loading && !loaded) return <LoadingProgress />;

    return (
        <Stack marginTop={4} spacing={2}>
            <SectionHeader title={title} subtitle={subtitle} />
            {regularQuestions?.map((question) => (
                <QuestionFactory key={question.id} question={question} onChange={onChange} />
            ))}
            <Box alignSelf={'flex-end'} width={'200px'}>
                <StyledSaveButton disabled={isSaveButtonDisabled} fullWidth onClick={onSubmit}>
                    Save
                </StyledSaveButton>
            </Box>
        </Stack>
    );
};
