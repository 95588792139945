import { Navigate } from 'react-router-dom';
import { appRoutes } from './AppRouter';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../auth/useAuth';
import { RootState } from '../redux/store';
import { LoadingProgress } from '../components/shared/LoadingProgress';

type Props = {
    children: React.ReactNode;
};

export const AuthRoute = ({ children }: Props) => {
    const { isAuthenticated, isLoadingEmailLogin, isLoadingGoogleLogin } = useAuth();
    const {
        user: { value: user },
    } = useSelector((state: RootState) => state.user);

    if (isLoadingEmailLogin || isLoadingGoogleLogin || (isAuthenticated && !user)) return <LoadingProgress />;

    return isAuthenticated ? <>{children}</> : <Navigate to={appRoutes.login} />;
};
