import React from 'react';
import { Grid } from '@mui/material';
import { AppConnectionLogo } from '../../shared/AppConnectionLogo';
import { ConnectionTypes, IConnectionDetailSchema } from '../../../api/ApiClientGenerated';
import { toReadableText } from '../../../utils/stringUtils';
import { SectionHeader } from '../../shared/SectionHeader';
import { SpacedGridItem } from '../../shared/styledComponents/StyledGrids';

type Props = {
    connection?: IConnectionDetailSchema;
};
export const LogoAndText = ({ connection }: Props) => {
    if (!connection) return <></>;

    return (
        <Grid item container height={'100%'} alignItems={'center'} direction={'row'} xs={4}>
            <SpacedGridItem item marginLeft={1}>
                <AppConnectionLogo name={connection.name} />
            </SpacedGridItem>

            <SpacedGridItem item height={'100%'}>
                <SectionHeader
                    title={toReadableText(connection.name)}
                    subtitle={connection.type === ConnectionTypes.KNOWLEDGE ? 'Knowledge' : 'Action'}
                />
            </SpacedGridItem>
        </Grid>
    );
};
