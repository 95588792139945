import { TitleSubtitle } from '../../../shared/TitleSubtitle';
import React, { ChangeEvent } from 'react';
import { Stack, TextField } from '@mui/material';

type Props = {
    botName?: string;
    onChangeText: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const BotName = ({ botName, onChangeText }: Props) => {
    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <TitleSubtitle
                title={'Set new name'}
                subtitle={'Instead of being called George, the bot will go by this name in Slack.'}
            />
            <Stack spacing={2} direction={'row'} alignItems={'center'}>
                <TextField placeholder={botName ?? 'Ex: Fred'} onChange={onChangeText} />
            </Stack>
        </Stack>
    );
};
