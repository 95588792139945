import { Grid, styled } from '@mui/material';

export const StyledGridContainer = styled(Grid)(() => ({
    margin: 0,
    padding: '20px',
    '& .MuiGrid-item': {
        padding: 0,
    },
    flexGrow: 1,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export const SpacedGridItem = styled(Grid)(({ theme }) => ({
    '&:not(:last-child)': {
        marginRight: theme.spacing(2),
    },
}));
