import { PageHeader } from '../shared/PageHeader';
import React from 'react';

export const AuthSuccess = () => {
    return (
        <PageHeader
            header={'Authorization Success'}
            subheader={
                "You've successfully authorized George. You can now close this page and ask your question again."
            }
        />
    );
};
