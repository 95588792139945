import { spawn } from 'redux-saga/effects';
import { connectionSagas } from './sagas/connectionSagas';
import { settingsSagas } from './sagas/settingsSagas';
import { userSagas } from './sagas/userSagas';

export function* rootSaga() {
    yield spawn(connectionSagas);
    yield spawn(settingsSagas);
    yield spawn(userSagas);
}
