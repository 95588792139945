import { Box, CircularProgress, Container } from '@mui/material';

export const LoadingProgress = () => {
    return (
        <Container>
            <Box
                marginTop={5}
                display={'flex'}
                flex={1}
                justifyContent={'center'}
                alignItems={'center'}
                height={'100%'}
                width={'100%'}
            >
                <CircularProgress />
            </Box>
        </Container>
    );
};
