import React from 'react';
import { Stack, Typography } from '@mui/material';
import { TitleSubtitleProps } from './Types';

export const TitleSubtitle = ({ title, subtitle }: TitleSubtitleProps) => {
    return (
        <Stack>
            <Typography>{title}</Typography>
            {subtitle && <Typography variant={'body2'}>{subtitle}</Typography>}
        </Stack>
    );
};
