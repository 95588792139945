import { Props } from './BrainIcon';

export const LightningIcon = ({ width, height }: Props) => (
    <svg
        width={width}
        height={height}
        viewBox="-8 0 41 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
    >
        <g clipPath="url(#clip0_23_615)">
            <path
                d="M6.875 25.7002C6.7525 25.7002 6.62875 25.664 6.52125 25.5902C6.2725 25.4202 6.18125 25.0965 6.30375 24.8215L10.3575 15.7002H4.375C4.1225 15.7002 3.89375 15.5477 3.7975 15.314C3.70125 15.0802 3.75375 14.8115 3.9325 14.6327L16.4325 2.13272C16.645 1.92022 16.98 1.88897 17.2288 2.05897C17.4775 2.22897 17.5688 2.55272 17.4463 2.82772L13.3925 11.949H19.375C19.6275 11.949 19.8563 12.1015 19.9525 12.3352C20.0488 12.569 19.9963 12.8377 19.8175 13.0165L7.3175 25.5165C7.19625 25.6377 7.03625 25.7002 6.875 25.7002ZM5.88375 14.4502H11.3188C11.53 14.4502 11.7275 14.5577 11.8425 14.7352C11.9575 14.9127 11.975 15.1365 11.89 15.3302L8.8125 22.254L17.865 13.2015H12.43C12.2188 13.2015 12.0212 13.094 11.9062 12.9165C11.7913 12.739 11.7738 12.5152 11.8588 12.3215L14.9363 5.39772L5.88375 14.4502Z"
                fill="#F5E9E2"
            />
        </g>
        <defs>
            <clipPath id="clip0_23_615">
                <rect width="25" height="25" fill="white" transform="translate(0 0.700195)" />
            </clipPath>
        </defs>
    </svg>
);
