import monkeyIcon from '../../assets/svg/MonkeyIcon.svg';
import React from 'react';

type Props = {
    height?: string;
};

export const MonkeyIcon = ({ height }: Props) => {
    return <img height={height} src={monkeyIcon} alt={'Monkey Icon.'} />;
};
