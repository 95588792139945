import { createTheme, responsiveFontSizes } from '@mui/material';

export const appColors = {
    albinoApe: '#FFFFFF',
    snowMonkey: '#F5E9E2',
    gorillaGrey: '#e0e0e0',
    baboonBeige: '#a0a0a0',
    orangutan: '#DD5B46',
    simianSage: '#668586',
    chimpCharcoal: '#2D3047',
};

let appTheme = createTheme({
    palette: {
        background: {
            paper: appColors.snowMonkey,
            default: appColors.albinoApe,
        },
        primary: {
            main: appColors.chimpCharcoal,
        },
        error: {
            main: appColors.orangutan,
        },
        text: {
            primary: appColors.chimpCharcoal,
            secondary: appColors.simianSage,
        },
    },
    typography: {
        fontFamily: ['Bricolage Grotesque', 'sans-serif'].join(','),
        h1: {
            fontWeight: 400,
            fontSize: '34px',
        },
        h2: {
            fontWeight: 600,
            fontSize: '24px',
        },
        h3: {
            fontWeight: 500,
            fontSize: '20px',
        },
        body1: {
            fontWeight: 400,
            fontSize: '16px',
        },
        body2: {
            fontWeight: 400,
            fontSize: '14px',
            color: appColors.simianSage,
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    width: '340px',
                    height: '195px',
                    borderRadius: '6px',
                    border: 'solid 1.5px',
                    borderColor: appColors.chimpCharcoal,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    borderRadius: '20px',
                    '&.Mui-disabled': {
                        backgroundColor: appColors.gorillaGrey,
                        color: appColors.baboonBeige,
                        cursor: 'not-allowed',
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    '&.Mui-checked': {
                        color: appColors.orangutan, // changes the thumb color
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: appColors.orangutan, // changes the track color
                    },
                },
            },
        },
    },
});

appTheme = responsiveFontSizes(appTheme);

export default appTheme;
