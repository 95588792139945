import React, { FC } from 'react';
import { PageHeader } from '../shared/PageHeader';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const UserProfile: FC = () => {
    const { user } = useSelector((state: RootState) => state.user);
    const userProfile = user.value;
    //TODO: implement user profile editable form

    return (
        <>
            <PageHeader header={'User Profile'} />
            {userProfile?.userName && <Typography>Name: {userProfile.userName}</Typography>}
        </>
    );
};
