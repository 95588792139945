import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { globalReducer, GlobalState } from './slices/globalSlice';
import { rootSaga } from './sagas';
import { connectionReducer, ConnectionState } from './slices/connectionSlice';
import { settingsReducer, SettingsState } from './slices/settingsSlice';
import { userReducer, UserState } from './slices/userSlice';

const sagaMiddleware = createSagaMiddleware();

interface ReducerState {
    connection: ConnectionState;
    global: GlobalState;
    settings: SettingsState;
    user: UserState;
}

const rootReducer = combineReducers<ReducerState>({
    connection: connectionReducer,
    global: globalReducer,
    settings: settingsReducer,
    user: userReducer,
});

export type RootState = ReducerState;

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
