import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import monkeyIcon from '../assets/svg/MonkeyIcon.svg';
import { Link } from 'react-router-dom';
import { appColors } from '../themes/AppTheme';
import { useAuth } from '../auth/useAuth';
import { appRoutes } from '../router/AppRouter';

export const Header = () => {
    const { logout } = useAuth();

    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    flexGrow={1}
                    component={Link}
                    to={appRoutes.knowledgeConnections.view}
                    sx={{ textDecoration: 'none', color: 'inherit' }}
                >
                    <img src={monkeyIcon} alt={'Monkey Icon.'} />
                    <Typography variant="h1" style={{ flexGrow: 1 }}>
                        George
                    </Typography>
                </Box>

                <Button style={{ color: appColors.albinoApe }} onClick={logout} variant="text">
                    Log Out
                </Button>
            </Toolbar>
        </AppBar>
    );
};
