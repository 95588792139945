import { useCallback, useMemo } from 'react';
import { AuthContext, initialAuthState, useAuth0Context } from './Auth0Provider';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Auth0DecodedHash, Auth0Error } from 'auth0-js';
import { TokenSingleton } from './TokenSingleton';
import { setErrorNotificationAction, setSuccessNotificationAction } from '../redux/slices/globalSlice';
import { getUserAction } from '../redux/slices/userSlice';
import { appRoutes } from '../router/AppRouter';

const LOCAL_STORAGE_IS_LOGGED_IN = 'isLoggedIn';
const LOCAL_STORAGE_REMEMBER_ACCOUNT = 'rememberAccount';

export const useAuth = () => {
    const { auth0, authState, setAuthState } = useAuth0Context() as AuthContext;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Check if the user is authenticated using local storage
    const isAuthenticated = localStorage.getItem(LOCAL_STORAGE_IS_LOGGED_IN) === 'true';

    const setRememberAccount = (rememberAccount: boolean) => {
        localStorage.setItem(LOCAL_STORAGE_REMEMBER_ACCOUNT, String(rememberAccount));
    };

    const showToastErrorAndNavigateHome = (err: Auth0Error | null) => {
        dispatch(
            setErrorNotificationAction({
                message: err?.error_description ?? err?.description ?? err?.error ?? 'Failed to authenticate',
            }),
        );

        // Reset loading states and navigate to the login page
        setAuthState((prevState) => ({
            ...prevState,
            isLoadingEmailLogin: false,
            isLoadingGoogleLogin: false,
        }));
        navigate(appRoutes.login);
    };

    const loginWithGoogle = useCallback(() => {
        setAuthState((prevState) => ({ ...prevState, isLoadingGoogleLogin: true }));
        auth0.authorize({
            scope: 'openid profile email',
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            connection: 'google-oauth2',
            redirectUri: `${window.location.origin}/auth/callback`,
        });
    }, [auth0]);

    const loginWithEmail = (email: string, password: string) => {
        setAuthState((prevState) => ({ ...prevState, isLoadingEmailLogin: true }));
        auth0.login(
            {
                email,
                password,
                realm: 'Username-Password-Authentication',
            },
            (err) => {
                if (err) {
                    showToastErrorAndNavigateHome(err);
                    return;
                }
                setAuthState({ ...authState, isLoadingEmailLogin: false });
            },
        );
    };

    const signupWithEmail = (email: string, password: string) => {
        setAuthState({ ...authState, isLoadingEmailLogin: true });
        auth0.signup(
            {
                email,
                password,
                connection: 'Username-Password-Authentication',
            },
            (err) => {
                if (err) {
                    showToastErrorAndNavigateHome(err);
                    return;
                }
                loginWithEmail(email, password);
            },
        );
    };

    const resetPassword = (email: string) => {
        auth0.changePassword(
            {
                email,
                connection: 'Username-Password-Authentication',
            },
            (err) => {
                if (err) {
                    dispatch(
                        setErrorNotificationAction({
                            message: err?.error_description ?? err?.description ?? err?.error,
                        }),
                    );
                    setAuthState({ ...authState, isLoadingEmailLogin: false, isLoadingGoogleLogin: false });
                    return;
                }
                dispatch(
                    setSuccessNotificationAction({ message: 'Check your email for a link to reset your password' }),
                );
            },
        );
    };

    const setSession = useCallback(
        async (authResult: Auth0DecodedHash) => {
            const expiresInMs = authResult.expiresIn! * 1000;
            const expiresAt = expiresInMs + Date.now();

            const isExpired = expiresAt <= Date.now();

            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('isAuthExpired', `${expiresAt <= Date.now()}`);

            if (isExpired) {
                try {
                    // Use the Auth0 SDK's checkSession method to renew the access token
                    const renewedAuthResult = await new Promise<Auth0DecodedHash>((resolve, reject) => {
                        auth0.checkSession({}, (err, result) => {
                            if (err) {
                                reject(err);
                            } else {
                                resolve(result);
                            }
                        });
                    });

                    // Update localStorage and authState with the new tokens
                    localStorage.setItem('isLoggedIn', 'true');
                    localStorage.setItem('isAuthExpired', 'false');

                    setAuthState({
                        ...authState,
                        accessToken: renewedAuthResult.accessToken!,
                        idToken: renewedAuthResult.idToken!,
                        expiresAt: renewedAuthResult.expiresIn! * 1000 + Date.now(),
                        expiresIn: renewedAuthResult.expiresIn!,
                        isAuthenticated: true,
                        isLoadingEmailLogin: false,
                        isLoadingGoogleLogin: false,
                    });
                } catch (error) {
                    console.error('Error renewing access token:', error);
                    dispatch(
                        setErrorNotificationAction({
                            message: 'Error renewing access token. Please log in again.',
                        }),
                    );

                    navigate(appRoutes.login);
                }
            } else {
                // Access token is still valid, no need for renewal
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem('isAuthExpired', 'false');

                setAuthState({
                    ...authState,
                    accessToken: authResult.accessToken!,
                    idToken: authResult.idToken!,
                    expiresAt,
                    expiresIn: authResult.expiresIn!,
                    isAuthenticated: true,
                    isLoadingEmailLogin: false,
                    isLoadingGoogleLogin: false,
                });
            }
        },
        [setAuthState, auth0],
    );

    const checkSession = useCallback(() => {
        // Check the session by calling the Auth0 checkSession method
        auth0.checkSession({}, (err, authResult) => {
            if (err) {
                dispatch(
                    setErrorNotificationAction({
                        message: err.error_description ?? err.description ?? err.error,
                    }),
                );
                logout();
                return;
            }

            // No error and got an auth result, renew session
            if (authResult) {
                TokenSingleton.setAccessToken(authResult.accessToken!);
                dispatch(getUserAction());

                auth0.client.userInfo(authResult.accessToken!, (err) => {
                    if (err) {
                        dispatch(
                            setErrorNotificationAction({
                                message: err.error_description ?? err.description ?? err.error,
                            }),
                        );
                        return;
                    }
                    setSession(authResult);
                });
            }
        });
    }, [auth0, setSession]);

    const handleAuthentication = useCallback(() => {
        auth0.parseHash((err, authResult) => {
            if (err || !authResult) {
                showToastErrorAndNavigateHome(err);
                return;
            }

            TokenSingleton.setAccessToken(authResult.accessToken!);
            dispatch(getUserAction());

            auth0.client.userInfo(authResult.accessToken!, (err) => {
                if (err) {
                    dispatch(
                        setErrorNotificationAction({
                            message: err.error_description ?? err.description ?? err.error,
                        }),
                    );
                    setAuthState({ ...authState, isLoadingEmailLogin: false, isLoadingGoogleLogin: false });
                } else {
                    setSession(authResult);
                }

                navigate(appRoutes.knowledgeConnections.view);
            });
        });
    }, [auth0, setSession]);

    const logout = useCallback(() => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('rememberAccount');

        // Execute Auth0 logout logic
        auth0.logout({
            returnTo: `${window.location.origin}${appRoutes.login}`,
        });

        // Assume logout was successful, set to initial state
        setAuthState(initialAuthState);
    }, [auth0, setAuthState]);

    const isLoadingEmailLogin = useMemo(() => {
        return authState.isLoadingEmailLogin;
    }, [authState]);

    const isLoadingGoogleLogin = useMemo(() => {
        return authState.isLoadingGoogleLogin;
    }, [authState]);

    return {
        checkSession,
        loginWithGoogle,
        loginWithEmail,
        signupWithEmail,
        resetPassword,
        logout,
        isLoadingEmailLogin,
        isLoadingGoogleLogin,
        isAuthenticated,
        handleAuthentication,
        setRememberAccount,
    };
};
