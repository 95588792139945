import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { getUserAction, IPutUpdateUserMessage, setUserAction, updateUserAction } from '../slices/userSlice';
import { userService } from '../../services/UserService';
import { GetSingleUserResponse, SwaggerResponse } from '../../api/ApiClientGenerated';

function* getUser() {
    try {
        const response: SwaggerResponse<GetSingleUserResponse> = yield call(userService.getUser);
        yield put(setUserAction({ ...response.result.user }));
    } catch (error) {
        console.error('Error in getUser saga:', error);
    }
}

function* updateUser(action: PayloadAction<IPutUpdateUserMessage>) {
    //TODO: implement when backend endpoint exists
    console.log('in update User saga', action.payload);
    // const response: IDashboardUserViewModel = yield callApi(userService.updateUser, action.payload);
    // yield put(setUserAction({ ...response }));
    // yield put(setSuccessNotificationAction({ message: 'Successfully updated profile' }));
}

export function* userSagas() {
    yield all([takeLatest(getUserAction.type, getUser), takeLatest(updateUserAction.type, updateUser)]);
}
