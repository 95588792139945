import React from 'react';
import { ConnectionTypes } from '../../api/ApiClientGenerated';
import { ConnectionList } from '../shared/ConnectionList';

export const KnowledgeConnections = () => {
    return (
        <ConnectionList
            type={ConnectionTypes.KNOWLEDGE}
            header={'Knowledge Connections'}
            subheader={'Connect George to these services to allow him to draw from them in his responses.'}
        />
    );
};
