import { ConnectionTypes, IConnectionListItemSchema } from '../../api/ApiClientGenerated';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { getConnectionListAction } from '../../redux/slices/connectionSlice';
import { appColors } from '../../themes/AppTheme';
import { Grid, Typography } from '@mui/material';
import { PageHeader } from './PageHeader';
import { Loadable } from '../../redux/ApiData';
import { appRoutes } from '../../router/AppRouter';
import { AppCard } from './ConnectionCard';

const typeToKeyMap: { [key in ConnectionTypes]: keyof RootState['connection'] } = {
    [ConnectionTypes.ACTION]: 'actionConnectionList',
    [ConnectionTypes.KNOWLEDGE]: 'knowledgeConnectionList',
};

const typeToRouteMap: { [key in ConnectionTypes]: keyof typeof appRoutes } = {
    [ConnectionTypes.ACTION]: 'actionConnections',
    [ConnectionTypes.KNOWLEDGE]: 'knowledgeConnections',
};

function isLoadable(obj: unknown): obj is Loadable<IConnectionListItemSchema[]> {
    return (obj as Loadable<IConnectionListItemSchema[]>).value !== undefined;
}

interface Props {
    type: ConnectionTypes;
    header: string;
    subheader: string;
}

export const ConnectionList = ({ type, header, subheader }: Props) => {
    const {
        user: { loading, loaded },
    } = useSelector((state: RootState) => state.user);
    const connectionListKey = typeToKeyMap[type];
    const routeKey = typeToRouteMap[type];
    const route = appRoutes[routeKey];
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const connectionListLoadable = useSelector((state: RootState) => state.connection[connectionListKey]);

    useEffect(() => {
        if (isLoadable(connectionListLoadable) && !loading && loaded) {
            dispatch(getConnectionListAction(type));
        }
    }, []);

    if (!isLoadable(connectionListLoadable)) return null;

    const connections = connectionListLoadable.value;

    const handleCardClick = (connection: IConnectionListItemSchema): void => {
        if (connection.id && typeof route === 'object' && 'actions' in route) {
            navigate(route.actions.configure.withId(connection.id));
        }
    };

    return (
        <>
            <PageHeader header={header} subheader={subheader} />
            <Typography color={appColors.simianSage}></Typography>
            <Grid
                container
                spacing={3}
                justifyContent={(connections?.length ?? 0) <= 2 ? 'flex-start' : 'space-between'}
            >
                {connections?.map((connection) => (
                    <Grid item key={connection.id}>
                        <AppCard
                            connection={connection}
                            connectionType={type}
                            onClick={() => handleCardClick(connection)}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
