import confluence from '../../assets/svg/confluence.svg';
import bookstack from '../../assets/svg/bookstack.svg';
import googleCalendar from '../../assets/svg/google-calendar.svg';
import github from '../../assets/svg/github.svg';
import azure from '../../assets/svg/azure.svg';
import google from '../../assets/svg/google.svg';
import monkeyIcon from '../../assets/svg/MonkeyIcon.svg';

type Props = {
    name: string;
};

const logoMap = {
    CONFLUENCE: confluence,
    BOOKSTACK: bookstack,
    GOOGLE_CALENDAR: googleCalendar,
    GOOGLE_SEARCH: google,
    AZURE_BLOB: azure,
    GITHUB: github,
    Unknown: null,
} as const;

type LogoMapKeys = keyof typeof logoMap;

export const AppConnectionLogo = ({ name }: Props) => {
    if (!name || !isValidLogoKey(name)) return <img src={monkeyIcon} alt={`${name} logo`} width={'70px'} />;

    function isValidLogoKey(key: string): key is LogoMapKeys {
        return [
            'CONFLUENCE',
            'BOOKSTACK',
            'GOOGLE_CALENDAR',
            'GOOGLE_SEARCH',
            'AZURE_BLOB',
            'GITHUB',
            'Unknown',
        ].includes(key);
    }

    const logoSrc = logoMap[name];

    if (!logoSrc) return <img src={monkeyIcon} alt={`${name} logo`} width={'70px'} />;

    return <img src={logoSrc} alt={`${name} logo`} width={'70px'} />;
};
