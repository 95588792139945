import React, { useEffect, useState } from 'react';
import { Stack, Switch } from '@mui/material';
import { TitleSubtitle } from '../TitleSubtitle';
import { QuestionProps } from '../Types';

const TRUE_STRING = 'true';

export const SwitchQuestion = ({ question, answer, onChange }: QuestionProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(answer?.value === TRUE_STRING);

    useEffect(() => {
        setIsChecked(answer?.value === TRUE_STRING);
    }, [answer]);

    const handleChange = (checked: boolean) => {
        if (!question.id) return;
        const newValue = checked ? TRUE_STRING : 'false';
        setIsChecked(checked);
        onChange({ id: answer?.id, questionId: question.id, newValue });
    };

    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <TitleSubtitle title={question.label ?? ''} subtitle={question.subLabel} />
            <Switch checked={isChecked} onChange={(event) => handleChange(event.target.checked)} />
        </Stack>
    );
};
