import { HttpService } from './base/HttpService';
import {
    customizationConfiguration,
    IPostSettingsResponse,
    IPostSettingsSchema,
    ISettings,
    LanguageModelOption,
    llmOptionsConfiguration,
} from '../redux/slices/settingsSlice';
import { SwaggerResponse } from '../api/ApiClientGenerated';

class InterfaceService extends HttpService {
    initiateSlackAuth = () => this.Client.slackInitiateAuth();
}

export const interfaceService = new InterfaceService();
