import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useEffect } from 'react';
import {
    customizationConfiguration,
    getSettingsAction,
    llmOptionsConfiguration,
    setCustomizationSettingsAction,
    setLlmSettingsAction,
    submitSettingsAction,
} from '../redux/slices/settingsSlice';

export const useSettings = () => {
    const dispatch = useDispatch();
    const { customization, llmOptions } = useSelector((state: RootState) => state.settings);

    useEffect(() => {
        dispatch(getSettingsAction());
    }, []);

    const updateCustomization = (key: keyof customizationConfiguration, value: any) => {
        const updatedSettings: customizationConfiguration = { ...customization, [key]: value };
        dispatch(setCustomizationSettingsAction(updatedSettings));
    };

    const updateLlm = (key: keyof llmOptionsConfiguration, value: any) => {
        const updatedSettings: llmOptionsConfiguration = { ...llmOptions, [key]: value };
        dispatch(setLlmSettingsAction(updatedSettings));
    };

    const onSubmit = () => {
        dispatch(submitSettingsAction());
    };

    return {
        updateCustomization,
        updateLlm,
        onSubmit,
    };
};
