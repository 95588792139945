import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../auth/useAuth';
import { LoginWithGoogleButton } from '../../auth/components/LoginWithGoogleButton';
import { appRoutes } from '../../router/AppRouter';
import { MonkeyIcon } from '../shared/MonkeyIcon';
import { LoginForm } from '../../auth/components/LoginForm';
import { SignupSection } from '../../auth/components/SignupSection';
import { LoginCard } from '../shared/styledComponents/StyledCards';
import { StyledBackgroundStack } from '../shared/styledComponents/StyledStacks';

export const LoginPage = () => {
    const { isAuthenticated } = useAuth();
    const [openSignUpDialog, setOpenSignUpDialog] = useState(false);

    if (isAuthenticated) return <Navigate to={appRoutes.knowledgeConnections.view} />;

    return (
        <StyledBackgroundStack direction={'row'}>
            <Stack flex={1} justifyContent={'center'} alignItems={'center'}>
                <LoginCard>
                    <Stack alignSelf={'center'} spacing={3} width={'90%'}>
                        <Stack spacing={2}>
                            <MonkeyIcon height={'72px'} />
                            <Typography
                                textAlign={'center'}
                                fontSize={'64px'}
                                fontWeight={800}
                                style={{ marginTop: 0 }}
                            >
                                George
                            </Typography>
                            <Typography textAlign={'center'} component={'h1'} variant={'h6'} fontWeight={'regular'}>
                                Enter your email and password below to log in
                            </Typography>
                        </Stack>
                        <LoginForm openSignUpDialog={openSignUpDialog} />
                        <LoginWithGoogleButton />
                        <SignupSection openSignUpDialog={openSignUpDialog} setOpenSignUpDialog={setOpenSignUpDialog} />
                    </Stack>
                </LoginCard>
            </Stack>
        </StyledBackgroundStack>
    );
};
