import { Stack, Typography } from '@mui/material';
import { SignUpDialog } from './SignUpDialog';
import React from 'react';

type Props = {
    openSignUpDialog: boolean;
    setOpenSignUpDialog: (open: boolean) => void;
};

export const SignupSection = ({ openSignUpDialog, setOpenSignUpDialog }: Props) => {
    return (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={5}>
            <Typography>Not registered yet?</Typography>
            <Typography sx={{ cursor: 'pointer' }} fontWeight={'bold'} onClick={() => setOpenSignUpDialog(true)}>
                Create an account
            </Typography>
            <SignUpDialog open={openSignUpDialog} onClose={() => setOpenSignUpDialog(false)} />
        </Stack>
    );
};
