import { Stack, styled } from '@mui/material';
import { appColors } from '../../../themes/AppTheme';

export const StyledBackgroundStack = styled(Stack)(() => ({
    height: '100vh',
    backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle.png'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
}));

export const StyledStack = styled(Stack)({
    borderColor: appColors.chimpCharcoal,
    minHeight: `100%`,
    backgroundColor: appColors.snowMonkey,
});
