import React from 'react';
import { ConnectionConfiguration } from '../shared/ConnectionConfiguration';
import { QuestionSection } from '../areas/configuration/QuestionSection';
import { LightningIcon } from '../../assets/LightningIcon';

export const ActionConfiguration = () => (
    <ConnectionConfiguration
        header={'Configure Action Connection'}
        subheader={'Give George access to new actions and abilities.'}
        IconComponent={<LightningIcon width="75px" height="75px" />}
    >
        <QuestionSection title={'Basic Permissions'} subtitle={'Set which actions George can take'} />
    </ConnectionConfiguration>
);
