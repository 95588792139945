import { Box, FormControl, Slider, styled } from '@mui/material';
import { appColors } from '../../../themes/AppTheme';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
});

export const PreviewImage = styled('img')(() => ({
    width: '150px',
    height: 'auto',
    objectFit: 'cover',
}));

export const StyledFormControl = styled(FormControl)(() => ({
    minWidth: '200px',
}));

export const StyledSlider = styled(Slider)({
    color: appColors.simianSage,
    height: 14,
    width: '90%',
    alignSelf: 'center',
    '& .MuiSlider-track': {
        border: 'none',
        height: 14,
    },
    '& .MuiSlider-thumb': {
        height: 25,
        width: 25,
        backgroundColor: appColors.albinoApe,
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-markLabel': {
        marginLeft: 10,
        paddingTop: 20,
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: appColors.simianSage,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

export const PreviewContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    border: `2px solid transparent`,
    display: 'inline-block',
    padding: theme.spacing(1),
    '&:hover': {
        border: `2px solid ${appColors.orangutan}`,
    },
}));
