import React, { MutableRefObject } from 'react';
import { TitleSubtitle } from '../../../shared/TitleSubtitle';
import { Button, Stack } from '@mui/material';
import { FileInput } from './FileInput';
import { ImagePreview } from './ImagePreview';

type Props = {
    imagePreviewUrl?: string;
    fileInputRef: MutableRefObject<HTMLInputElement | null>;
    onFileChange: (file: File) => void;
    onClick: () => void;
    onDelete: () => void;
};

export const IconUpload = ({ imagePreviewUrl, onFileChange, onClick, fileInputRef, onDelete }: Props) => {
    return (
        <>
            <FileInput onFileChange={onFileChange} fileInputRef={fileInputRef} />
            <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleSubtitle
                    title={'Upload a custom icon'}
                    subtitle={'This is what users will see when interacting with George in Slack.'}
                />
                <Stack>
                    <ImagePreview imagePreviewUrl={imagePreviewUrl} onDelete={onDelete} />
                    <Button onClick={onClick}>{imagePreviewUrl ? 'Edit' : 'Upload'}</Button>
                </Stack>
            </Stack>
        </>
    );
};
