import { call, put } from 'redux-saga/effects';
import { setDefaultErrorNotification, setErrorNotificationAction } from '../slices/globalSlice';
import { ApiException, SwaggerResponse } from '../../api/ApiClientGenerated';

type SafeCallResponse<T> = {
    response?: SwaggerResponse<T>;
    error?: Error;
};

function* safeCall<T>(effect: any, ...params: any[]) {
    try {
        return { response: yield call(effect, ...params) } as SafeCallResponse<T>;
    } catch (err) {
        const error = err as Error;
        return { error } as SafeCallResponse<T>;
    }
}

function* handleUnauthenticatedResponse(response: SafeCallResponse<never>) {
    const apiException = response.error as ApiException;
    if (apiException && apiException.status === 401) {
        yield put(setErrorNotificationAction({ message: 'Your session has expired and you have been logged out.' }));
        return;
    }
}

function* handleApiResponse(response: SafeCallResponse<never>) {
    const status = response.response?.status;
    if (response.error?.message) {
        yield put(setDefaultErrorNotification({ debugMessage: response.error.message }));
        return;
    }
    if (status && status >= 300) {
        yield put(setDefaultErrorNotification({ debugMessage: `Request failed with status ${status}.` }));
    }
}

export function* callApi(fn: any, ...args: any[]) {
    const response: SafeCallResponse<never> = yield safeCall(fn, ...args);
    yield handleUnauthenticatedResponse(response);
    yield handleApiResponse(response);
    return response.response?.result;
}
