import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ActivationStatusTypes,
    ConnectionStatusTypes,
    ConnectionTypes,
    IConnectionDetailSchema,
    IConnectionListItemSchema,
    IGetAnswerListResponse,
    IPostAnswerListResponse,
    IPostAnswerSchema,
} from '../../api/ApiClientGenerated';
import { initLoadable, Loadable } from '../ApiData';

export type ConnectionState = {
    actionConnectionList: Loadable<IConnectionListItemSchema[]>;
    connectionDetails: Loadable<IConnectionDetailSchema | undefined>;
    knowledgeConnectionList: Loadable<IConnectionListItemSchema[]>;
    answerConfiguration: Loadable<IGetAnswerListResponse>;
};

const defaultConnectionDetail: IConnectionDetailSchema = {
    id: 0,
    activation_status_type: ActivationStatusTypes.MISSING,
    connection_status_type: ConnectionStatusTypes.MISSING,
    description: '',
    name: '',
    display_name: '',
    type: ConnectionTypes.KNOWLEDGE,
};

const createInitialState = (): ConnectionState => ({
    actionConnectionList: initLoadable([]),
    connectionDetails: initLoadable(defaultConnectionDetail),
    knowledgeConnectionList: initLoadable([]),
    answerConfiguration: initLoadable({
        answers: [],
        connectionStatus: ConnectionStatusTypes.INCOMPLETE,
    }),
});

const initialState = createInitialState();

const connectionConfigSlice = createSlice({
    name: 'connection',
    initialState,
    reducers: {
        //CONNECTION LIST
        getConnectionListAction: (state, action: PayloadAction<ConnectionTypes>) => {
            state.knowledgeConnectionList.loaded = false;
            state.knowledgeConnectionList.loading = true;
        },
        setKnowledgeConnectionListAction: (state, action: PayloadAction<IConnectionListItemSchema[] | undefined>) => {
            state.knowledgeConnectionList.loaded = true;
            state.knowledgeConnectionList.loading = false;
            state.knowledgeConnectionList.value = action.payload;
        },
        setActionConnectionListAction: (state, action: PayloadAction<IConnectionListItemSchema[] | undefined>) => {
            state.actionConnectionList.loaded = true;
            state.actionConnectionList.loading = false;
            state.actionConnectionList.value = action.payload;
        },
        //ANSWERS
        getAnswerConfigurationAction: (state, action: PayloadAction<number>) => {
            state.answerConfiguration.loading = true;
            state.answerConfiguration.loaded = false;
        },
        setAnswerConfigurationAction: (state, action: PayloadAction<IGetAnswerListResponse>) => {
            state.answerConfiguration.loading = false;
            state.answerConfiguration.loaded = true;
            state.answerConfiguration.value = action.payload;
        },
        submitAnswerConfigurationAction: (state, action: PayloadAction<IPostAnswerSchema[]>) => {},
        //CONNECTION DETAILS
        getConnectionDetailsAction: (state, action: PayloadAction<number>) => {
            state.connectionDetails.loading = true;
            state.connectionDetails.loaded = false;
        },
        setConnectionDetailsAction: (state, action: PayloadAction<IConnectionDetailSchema>) => {
            state.connectionDetails.loading = false;
            state.connectionDetails.loaded = true;
            state.connectionDetails.value = action.payload;
        },
        updateConnectionStatusAction: (state, action: PayloadAction<ConnectionStatusTypes>) => {
            if (state.connectionDetails.value) {
                state.connectionDetails.value.connection_status_type = action.payload;
            }
        },
        handleDisconnectAction: (state, action: PayloadAction<number>) => {},
    },
});

export const {
    getConnectionListAction,
    setActionConnectionListAction,
    setKnowledgeConnectionListAction,

    getAnswerConfigurationAction,
    setAnswerConfigurationAction,
    submitAnswerConfigurationAction,

    getConnectionDetailsAction,
    setConnectionDetailsAction,
    updateConnectionStatusAction,

    handleDisconnectAction,
} = connectionConfigSlice.actions;

export const connectionReducer = connectionConfigSlice.reducer;
