import { Checkbox, FormControlLabel } from '@mui/material';
import { useAuth } from '../useAuth';

export const RememberAccountCheckbox = () => {
    const { setRememberAccount } = useAuth();
    return (
        <FormControlLabel
            control={<Checkbox onChange={(e) => setRememberAccount(e.target.checked)} />}
            label={'Remember this account'}
        />
    );
};
