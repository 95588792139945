import React, { FormEvent, useRef, useState } from 'react';
import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { RememberAccountCheckbox } from './RememberAccountCheckbox';
import { ForgotPasswordDialog } from './ForgotPasswordDialog';
import { useAuth } from '../useAuth';

type Props = {
    openSignUpDialog: boolean;
};
export const LoginForm = ({ openSignUpDialog }: Props) => {
    const { loginWithEmail, isLoadingGoogleLogin, isLoadingEmailLogin } = useAuth();
    const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const formRef = useRef<HTMLFormElement | null>(null);

    const handleLogin = (e: FormEvent) => {
        e.preventDefault();
        const isValid = formRef.current?.reportValidity();
        isValid && loginWithEmail(email, password);
    };

    return (
        <form ref={formRef} onSubmit={(e) => handleLogin(e)}>
            <Stack spacing={2}>
                <TextField
                    label={'Email'}
                    fullWidth
                    variant={'filled'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <TextField
                    label={'Password'}
                    type={'password'}
                    fullWidth
                    variant={'filled'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <RememberAccountCheckbox />
                    <Typography
                        sx={{ cursor: 'pointer' }}
                        fontWeight={'bold'}
                        onClick={() => setOpenForgotPasswordDialog(true)}
                    >
                        Forgot password?
                    </Typography>
                    <ForgotPasswordDialog
                        open={openForgotPasswordDialog}
                        onClose={() => setOpenForgotPasswordDialog(false)}
                    />
                </Stack>
                <Button
                    variant={'contained'}
                    type={'submit'}
                    fullWidth
                    disabled={
                        openSignUpDialog || openForgotPasswordDialog || isLoadingEmailLogin || isLoadingGoogleLogin
                    }
                >
                    {isLoadingEmailLogin ? <CircularProgress /> : 'Login'}
                </Button>
            </Stack>
        </form>
    );
};
