import { useDispatch, useSelector } from 'react-redux';
import { clearNotificationAction } from '../../redux/slices/globalSlice';
import { Snackbar, Stack } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React, { SyntheticEvent } from 'react';
import { RootState } from '../../redux/store';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ToastNotification = () => {
    const { message, isOpen, severity } = useSelector((state: RootState) => state.global.notification);
    const dispatch = useDispatch();

    const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(clearNotificationAction());
    };

    if (!message || !severity) return <></>;

    return (
        <Stack>
            <Snackbar
                open={isOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
};
