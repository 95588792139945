import { TitleSubtitle } from '../../../shared/TitleSubtitle';
import { InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { LanguageModelOption } from '../../../../redux/slices/settingsSlice';
import React, { ReactNode } from 'react';
import { StyledFormControl } from '../../../shared/styledComponents/StyledComponents';

type Props = {
    defaultLanguageModel?: LanguageModelOption;
    onChange: (event: SelectChangeEvent, child: ReactNode) => void;
};

export const DefaultLanguageModel = ({
    defaultLanguageModel = LanguageModelOption.ChatGpt3Point5,
    onChange,
}: Props) => {
    const languageModelOptions = Object.keys(LanguageModelOption).map((key) => {
        const value = LanguageModelOption[key as keyof typeof LanguageModelOption];
        return (
            <MenuItem key={key} value={value}>
                {value}
            </MenuItem>
        );
    });

    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <TitleSubtitle
                title={'Set default language model'}
                subtitle={'This determines which language model George will use as a default.'}
            />
            <StyledFormControl>
                <InputLabel>Default language model</InputLabel>

                <Select value={defaultLanguageModel || ''} label={'Default language model'} onChange={onChange}>
                    {languageModelOptions}
                </Select>
            </StyledFormControl>
        </Stack>
    );
};
