import React from 'react';
import { Typography } from '@mui/material';
import { TitleSubtitleProps } from './Types';

export const SectionHeader = ({ title, subtitle }: TitleSubtitleProps) => {
    return (
        <>
            <Typography variant={'h3'} fontWeight={600}>
                {title}
            </Typography>
            {subtitle && <Typography variant={'body2'}>{subtitle}</Typography>}
        </>
    );
};
