import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useAuth } from '../useAuth';

export const LoginWithGoogleButton = () => {
    const { loginWithGoogle, isLoadingGoogleLogin, isLoadingEmailLogin } = useAuth();

    return (
        <Button
            onClick={() => loginWithGoogle()}
            variant="outlined"
            disabled={isLoadingGoogleLogin || isLoadingEmailLogin}
            startIcon={<img src={'/googleIcon.svg'} alt={'google icon'} />}
        >
            {isLoadingGoogleLogin ? <CircularProgress /> : 'Login with google'}
        </Button>
    );
};
