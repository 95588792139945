import { TokenSingleton } from '../../auth/TokenSingleton';
import { ApiClient } from '../../api/ApiClientGenerated';

export class HttpService {
    constructor() {
        this.Client = new ApiClient(process.env.REACT_APP_API_URL, {
            fetch: async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
                const token = TokenSingleton.getAccessToken();
                return fetch(url, {
                    ...init,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
            },
        });
    }

    public Client: ApiClient;
}
