import { Stack } from '@mui/material';
import { SectionHeader } from '../../../shared/SectionHeader';
import { IconUpload } from './IconUpload';
import { BotName } from './BotName';
import React, { ChangeEvent, useCallback, useRef } from 'react';
import * as _ from 'lodash';
import { useSettings } from '../../../../hooks/useSettings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

const DEBOUNCE_TIME = 2000;

export const CustomizationSection = () => {
    const { botName, iconUrl } = useSelector((state: RootState) => state.settings.customization);
    const { updateCustomization } = useSettings();
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const debouncedSaveBotName = useCallback(
        _.debounce((newName: string) => {
            updateCustomization('botName', newName);
        }, DEBOUNCE_TIME),
        [updateCustomization],
    );

    const handleBotNameTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        const currentName = event.target.value;
        debouncedSaveBotName(currentName);
        updateCustomization('botName', currentName);
    };

    const readFile = useCallback(
        (file: File) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                updateCustomization('iconUrl', reader.result as string);
            };
            reader.readAsDataURL(file);
        },
        [updateCustomization],
    );

    const handleUploadClick = useCallback((): void => {
        // Trigger the hidden file input element to open the file dialog
        fileInputRef.current?.click();
    }, []);

    const handleDeleteIcon = () => {
        updateCustomization('iconUrl', undefined);
    };

    const handleFileChange = useCallback(
        (file: File) => {
            readFile(file);
        },
        [readFile],
    );

    return (
        <Stack spacing={2}>
            <SectionHeader title={'Customization'} subtitle={'Give George a custom look and feel'} />
            <IconUpload
                onClick={handleUploadClick}
                imagePreviewUrl={iconUrl}
                onFileChange={handleFileChange}
                fileInputRef={fileInputRef}
                onDelete={handleDeleteIcon}
            />
            <BotName botName={botName} onChangeText={handleBotNameTextChange} />
        </Stack>
    );
};
