import { appColors } from '../../../themes/AppTheme';
import { Card, styled } from '@mui/material';
import { sharedBorderStyles } from './StyledButtons';

export const ConnectionCard = createStyledCard(appColors.snowMonkey, appColors.chimpCharcoal);

// Function to create a styled card with given colors
function createStyledCard(backgroundColor: string, borderColor: string) {
    return styled(Card)({
        ...sharedBorderStyles,
        backgroundColor,
        borderColor,
        width: '100%',
        height: '100px',
        borderRadius: '6px',
    });
}

// Card for login interface
export const LoginCard = styled(Card)({
    width: '60%',
    height: 'fit-content',
    padding: 25,
    display: 'flex',
    justifyContent: 'center',
});
