import React from 'react';
import { BrainIcon } from '../../assets/BrainIcon';
import { ConnectionConfiguration } from '../shared/ConnectionConfiguration';
import { QuestionSection } from '../areas/configuration/QuestionSection';

export const KnowledgeConfiguration = () => (
    <ConnectionConfiguration
        header={'Configure Knowledge Connection'}
        subheader="Give George a new source of data."
        IconComponent={<BrainIcon width="75px" height="75px" />}
    >
        <QuestionSection title={'Options'} subtitle={'Set what George does and does not have access to'} />
    </ConnectionConfiguration>
);
