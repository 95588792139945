import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store';
import { Provider } from 'react-redux';
import { Auth0Provider } from './auth/Auth0Provider';
import { EnsureAuth } from './auth/EnsureAuth';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Auth0Provider>
                    <EnsureAuth>
                        <App />
                    </EnsureAuth>
                </Auth0Provider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
);
