import React, { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';
import { appColors } from '../../themes/AppTheme';

type Props = {
    header: string;
    subheader?: string;
    AdditionalHeaderContent?: () => ReactElement;
};
export const PageHeader = ({ header, subheader, AdditionalHeaderContent }: Props) => {
    return (
        <Stack marginBottom={2}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack>
                    <Typography variant={'h1'}>{header}</Typography>
                    {subheader && <Typography color={appColors.simianSage}>{subheader}</Typography>}
                </Stack>
                {AdditionalHeaderContent && <AdditionalHeaderContent />}
            </Stack>
        </Stack>
    );
};
