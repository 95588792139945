import { Box } from '@mui/material';
import { appColors } from '../../themes/AppTheme';
import React, { ReactElement } from 'react';

type Props = {
    icon: ReactElement;
};

export const HeaderContentIcon = ({ icon }: Props) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={75}
            height={75}
            borderRadius="50%"
            bgcolor={appColors.simianSage}
        >
            {icon}
        </Box>
    );
};
