import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getConnectionDetailsAction } from '../redux/slices/connectionSlice';

export const useFetchConnection = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const fetchConnection = async () => {
            const parsedId = Number(id);
            if (!isNaN(parsedId)) {
                dispatch(getConnectionDetailsAction(parsedId));
            } else {
                console.error(`Invalid connectionId: ${id}`);
            }
        };

        id && fetchConnection();
    }, [id]);
};
