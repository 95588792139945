import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from '../Header';
import { Box, Stack } from '@mui/material';
import { appRoutes } from '../../router/AppRouter';
import { Navigation } from '../areas/navigation/Navigation';

type Props = {
    children: ReactNode;
};

const layoutStyles = (hideAppBarAndNavigation: boolean) => ({
    marginX: hideAppBarAndNavigation ? 0 : 5,
});

export const Layout = ({ children }: Props) => {
    const location = useLocation();
    const hideAppBarAndNavigation = location.pathname === appRoutes.login;
    const { marginX } = layoutStyles(hideAppBarAndNavigation);

    return (
        <>
            {!hideAppBarAndNavigation && <Header />}
            <Box display={'flex'} height={'100vh'} sx={{ overflow: 'hidden' }}>
                {!hideAppBarAndNavigation && (
                    <Box width="25%" sx={{ overflowY: 'hidden' }}>
                        <Navigation />
                    </Box>
                )}
                <Box
                    width={hideAppBarAndNavigation ? '100%' : '75%'}
                    sx={{ overflowY: 'auto', pt: hideAppBarAndNavigation ? 0 : '40px' }}
                >
                    <Stack marginX={marginX} marginTop={marginX}>
                        {children}
                    </Stack>
                </Box>
            </Box>
        </>
    );
};
