import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
    getAnswerConfigurationAction,
    getConnectionDetailsAction,
    getConnectionListAction,
    handleDisconnectAction,
    setActionConnectionListAction,
    setAnswerConfigurationAction,
    setConnectionDetailsAction,
    setKnowledgeConnectionListAction,
    submitAnswerConfigurationAction,
    updateConnectionStatusAction,
} from '../slices/connectionSlice';
import {
    ConnectionStatusTypes,
    ConnectionTypes,
    IGetAnswerListResponse,
    IGetConnectionListResponse,
    IGetSingleConnectionResponse,
    IPostAnswerSchema,
    PostAnswerListResponse,
} from '../../api/ApiClientGenerated';
import { callApi } from './callApi';
import { connectionService } from '../../services/ConnectionService';
import { PayloadAction } from '@reduxjs/toolkit';
import { connectionSerializer } from '../slices/connectionSerializer';
import { setSuccessNotificationAction } from '../slices/globalSlice';
import { RootState } from '../store';

function* getAnswerConfiguration(action: PayloadAction<number>) {
    const response: IGetAnswerListResponse = yield callApi(connectionService.getAnswers, action.payload);
    yield put(setAnswerConfigurationAction(response));
}

function* getConnectionDetails(action: PayloadAction<number>) {
    const response: IGetSingleConnectionResponse = yield callApi(
        connectionService.getConnectionDetails,
        action.payload,
    );

    const serializedConnection = connectionSerializer.serializeConnectionDetailSchema(response.connection);
    // @ts-ignore
    yield put(setConnectionDetailsAction(serializedConnection));
}

function* getConnectionList(action: PayloadAction<ConnectionTypes>) {
    const response: IGetConnectionListResponse = yield callApi(connectionService.getConnectionsList, action.payload);
    if (response) {
        switch (action.payload) {
            case ConnectionTypes.KNOWLEDGE:
                yield put(setKnowledgeConnectionListAction(response.results));
                break;
            case ConnectionTypes.ACTION:
                yield put(setActionConnectionListAction(response.results));
                break;
            default:
                break;
        }
    }
}

function* submitAnswerConfiguration(action: PayloadAction<IPostAnswerSchema[]>) {
    const { value } = yield select((state: RootState) => state.connection.connectionDetails);
    const id = value?.id;
    if (!id) return;
    const response: PostAnswerListResponse = yield callApi(
        connectionService.addConnectionAnswersConfiguration,
        id,
        action.payload,
    );
    yield put(updateConnectionStatusAction(ConnectionStatusTypes.COMPLETE));
    yield put(setSuccessNotificationAction({ message: 'Successfully saved configuration' }));
    const serializedAnswers = connectionSerializer.serializePostAnswerListResponseSchema(response);
    //@ts-ignore
    yield put(setAnswerConfigurationAction(serializedAnswers));
}

function* handleDisconnect(action: PayloadAction<number>) {
    yield callApi(connectionService.handleDisconnect, action.payload);
    yield put(setSuccessNotificationAction({ message: `Successfully removed connection` }));
}

export function* connectionSagas() {
    yield all([
        takeLatest(getAnswerConfigurationAction.type, getAnswerConfiguration),
        takeLatest(submitAnswerConfigurationAction.type, submitAnswerConfiguration),
        takeLatest(getConnectionDetailsAction.type, getConnectionDetails),
        takeLatest(getConnectionListAction.type, getConnectionList),
        takeLatest(handleDisconnectAction.type, handleDisconnect),
    ]);
}
