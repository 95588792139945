import React, { forwardRef, useState } from 'react';
import { PreviewContainer, PreviewImage } from '../../../shared/styledComponents/StyledComponents';
import { DeleteOverlay } from '../../../shared/styledComponents/StyledButtons';

type Props = {
    imagePreviewUrl: string | undefined;
    onDelete: () => void;
};

export const ImagePreview = forwardRef(({ imagePreviewUrl, onDelete }: Props, ref: any) => {
    const [isHovered, setIsHovered] = useState(false);

    if (!imagePreviewUrl) return <></>;
    return (
        <PreviewContainer ref={ref} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <PreviewImage src={imagePreviewUrl} alt={'Preview'} />
            {isHovered && <DeleteOverlay onClick={onDelete}>Delete</DeleteOverlay>}
        </PreviewContainer>
    );
});
