import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GlobalState {
    notification: {
        message: string | undefined;
        isOpen: boolean;
        severity: 'success' | 'error' | 'info' | undefined;
    };
}

const initialState: GlobalState = {
    notification: {
        message: undefined,
        isOpen: false,
        severity: undefined,
    },
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        clearNotificationAction: (state) => {
            state.notification.message = undefined;
            state.notification.isOpen = false;
            state.notification.severity = undefined;
        },
        setDefaultErrorNotification: (state, action: PayloadAction<{ debugMessage: string } | undefined>) => {
            state.notification.message = 'Something went wrong. Please refresh the page and try again.';
            state.notification.isOpen = true;
            state.notification.severity = 'error';
            if (action.payload) {
                console.error(action.payload.debugMessage);
            }
        },
        setErrorNotificationAction: (state, action: PayloadAction<{ message: string }>) => {
            state.notification.message = action.payload.message;
            state.notification.isOpen = true;
            state.notification.severity = 'error';
        },
        setInfoNotificationAction: (state, action: PayloadAction<{ message: string }>) => {
            state.notification.message = action.payload.message;
            state.notification.isOpen = true;
            state.notification.severity = 'info';
        },
        setSuccessNotificationAction: (state, action: PayloadAction<{ message: string }>) => {
            state.notification.message = action.payload.message;
            state.notification.isOpen = true;
            state.notification.severity = 'success';
        },
    },
});

export const {
    setSuccessNotificationAction,
    setErrorNotificationAction,
    clearNotificationAction,
    setDefaultErrorNotification,
} = globalSlice.actions;

export const globalReducer = globalSlice.reducer;
