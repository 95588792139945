import { Stack, TextField } from '@mui/material';
import { TitleSubtitle } from '../../../shared/TitleSubtitle';
import React, { ChangeEvent, FocusEventHandler } from 'react';

type Props = {
    systemPrompts?: string;
    onChangeText: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const SystemPrompts = ({ systemPrompts, onChangeText }: Props) => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleSubtitle
                    title={'System prompts'}
                    subtitle={'Add additional text that will help George answer queries the way you like.'}
                />
            </Stack>
            <Stack spacing={2} direction={'row'} alignItems={'center'}>
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    placeholder={systemPrompts ?? 'Ex: MJL employees are known as “MJLers”.'}
                    onChange={onChangeText}
                />
            </Stack>
        </>
    );
};
