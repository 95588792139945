import React, { useState } from 'react';
import { Box, Button, Divider, List, Stack, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import ellipses from '../../../assets/svg/ellipses.svg';
import { appColors } from '../../../themes/AppTheme';
import { RouteItem } from './RouteItem';
import { appRoutes } from '../../../router/AppRouter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { StyledStack } from '../../shared/styledComponents/StyledStacks';
import { AddToSlackButton } from '../../AddToSlackButton';

export const Navigation = () => {
    const { value, loaded, loading } = useSelector((state: RootState) => state.user.user);
    const [activeItem, setActiveItem] = useState<string | null>(null);

    const toggleActiveItem = (item: string) => {
        setActiveItem((prev) => (prev === item ? null : item));
    };

    return (
        <StyledStack>
            <Box flex={1}>
                <Stack spacing={2} marginTop={2}>
                    <Typography paddingLeft={2} variant={'h2'}>
                        Configure George
                    </Typography>
                    <Divider style={{ color: appColors.chimpCharcoal }} />
                </Stack>
                <List>
                    {Object.entries(appRoutes)
                        .filter(
                            ([key]) =>
                                key !== 'profile' && key !== 'login' && key !== 'callback' && key !== 'authSuccess',
                        )
                        .map(([key, route]) => (
                            <RouteItem
                                key={key}
                                keyName={key}
                                route={typeof route === 'string' ? route : route.view}
                                isActive={key === activeItem}
                                toggleActive={toggleActiveItem}
                            />
                        ))}
                    <AddToSlackButton />
                </List>
            </Box>
            <Box m={2} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                {!loading && loaded && (
                    <>
                        <Typography fontWeight={800} color={appColors.simianSage} variant={'h2'}>
                            Hi {value?.username ?? 'there'}
                        </Typography>
                        <NavLink to={appRoutes.profile}>
                            <img src={ellipses} alt={'ellipses'} />
                        </NavLink>
                    </>
                )}
            </Box>
        </StyledStack>
    );
};
