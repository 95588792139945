import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import React from 'react';
import { AppConnectionLogo } from '../../shared/AppConnectionLogo';
import { ConfigurationTextField } from '../../shared/ConfigurationTextField';
import { ConnectionStatusTypes, Question } from '../../../api/ApiClientGenerated';
import { toReadableText } from '../../../utils/stringUtils';
import { SectionHeader } from '../../shared/SectionHeader';
import { DialogProps } from '../../shared/Types';
import { StyledToggleButton } from '../../shared/styledComponents/StyledButtons';
import { useAnswerUpdates } from '../../../hooks/useAnswerUpdates';

type Props = DialogProps & {
    questions: Question[];
};

export const ConnectionDialog = ({ connection, open, handleClose, questions }: Props) => {
    const isConnected = connection.connection_status_type === ConnectionStatusTypes.COMPLETE ?? false;
    const { onChange, onSubmit, areAllIndexQuestionsAnswered } = useAnswerUpdates();

    const handleSubmit = () => {
        onSubmit();
        handleClose();
    };

    return (
        <Dialog fullWidth maxWidth={'md'} open={open} onClose={handleClose}>
            <Stack padding={2}>
                <DialogTitle>
                    <Stack spacing={2} direction={'row'}>
                        <AppConnectionLogo name={connection.name} />
                        <Stack>
                            <SectionHeader
                                title={toReadableText(connection.name)}
                                subtitle={`Configure the connection to your ${connection.name} instance here.`}
                            />
                        </Stack>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        {questions?.map((question) => (
                            <ConfigurationTextField
                                key={question.id}
                                title={question.label ?? ''}
                                subtitle={question.subLabel}
                                onChange={(newValue) => {
                                    if (!question.id) return;
                                    onChange({ questionId: question.id, newValue });
                                }}
                            />
                        ))}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <StyledToggleButton
                        disabled={!areAllIndexQuestionsAnswered}
                        connected={isConnected}
                        onClick={handleSubmit}
                    >
                        Connect!
                    </StyledToggleButton>
                </DialogActions>
            </Stack>
        </Dialog>
    );
};
