import React from 'react';
import { ConnectionTypes } from '../../api/ApiClientGenerated';
import { ConnectionList } from '../shared/ConnectionList';

export const ActionConnections = () => {
    return (
        <ConnectionList
            type={ConnectionTypes.ACTION}
            header={'Action Connections'}
            subheader={'Connect George to these services to allow him to take action for you.'}
        />
    );
};
