import { TitleSubtitle } from '../../../shared/TitleSubtitle';
import React from 'react';
import { Box, Stack, Switch } from '@mui/material';
import { StyledSlider } from '../../../shared/styledComponents/StyledComponents';

type Props = {
    isCustomTemperatureEnabled: boolean;
    customTemperature: number | null;
    onSliderChange: (event: Event, newValue: number | number[], activeThumb: number) => void;
    onToggle: () => void;
};

export const CustomTemperature = ({
    isCustomTemperatureEnabled,
    customTemperature,
    onSliderChange,
    onToggle,
}: Props) => {
    // Represents marks on the slider
    const sliderMarks = [
        {
            value: 0,
            label: 'Serious',
        },
        {
            value: 10,
            label: 'Fun',
        },
    ];

    // Helper function to format slider values
    const formatSliderValue = (value: number | null): string => {
        return `${value}`;
    };

    return (
        <Box>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleSubtitle
                    title={'Custom Temperature'}
                    subtitle={'This will change George’s personality in his responses.'}
                />
                <Switch checked={isCustomTemperatureEnabled} onChange={onToggle} />
            </Stack>
            {isCustomTemperatureEnabled && (
                <Box marginY={2} display="flex" justifyContent="center" width="100%">
                    <StyledSlider
                        valueLabelDisplay="auto"
                        value={customTemperature ?? 5}
                        min={0}
                        max={10}
                        step={1}
                        onChange={onSliderChange}
                        aria-label={'Custom temperature slider'}
                        marks={sliderMarks}
                        getAriaValueText={formatSliderValue}
                    />
                </Box>
            )}
        </Box>
    );
};
