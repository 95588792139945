import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initLoadable, Loadable } from '../ApiData';

//TODO: remove this once we get from BE
export enum LanguageModelOption {
    ChatGpt3Point5 = 'ChatGPT 3.5',
    ChatGpt4 = 'ChatGPT 4',
}

export interface IPostSettingsSchema {
    settings: ISettings;
}

export interface IPostSettingsResponse {
    customization: customizationConfiguration;
    llmOptions: llmOptionsConfiguration;
}

export interface ISettings {
    customization: customizationConfiguration;
    llmOptions: llmOptionsConfiguration;
}

export interface SettingsState {
    customization: customizationConfiguration;
    llmOptions: llmOptionsConfiguration;
}

export interface llmOptionsConfiguration {
    isThinkHarderEnabled: boolean;
    defaultLanguageModel: LanguageModelOption;
    isCustomTemperatureEnabled: boolean;
    customTemperature: number | null;
    systemPrompts: string | undefined;
}

export interface customizationConfiguration {
    botName?: string | undefined;
    iconUrl?: string | undefined;
}

const initialState: SettingsState = {
    customization: {
        iconUrl: undefined,
        botName: undefined,
    },
    llmOptions: {
        isThinkHarderEnabled: false,
        defaultLanguageModel: LanguageModelOption.ChatGpt3Point5,
        isCustomTemperatureEnabled: false,
        customTemperature: null,
        systemPrompts: undefined,
    },
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        getSettingsAction: (state) => {},
        setCustomizationSettingsAction: (state, action: PayloadAction<customizationConfiguration>) => {
            state.customization = action.payload;
        },
        setLlmSettingsAction: (state, action: PayloadAction<llmOptionsConfiguration>) => {
            state.llmOptions = action.payload;
        },
        submitSettingsAction: (state) => {},
    },
});

export const { getSettingsAction, submitSettingsAction, setLlmSettingsAction, setCustomizationSettingsAction } =
    settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
