import { Stack, TextField } from '@mui/material';
import React from 'react';
import { TitleSubtitle } from './TitleSubtitle';
import { TitleSubtitleProps } from './Types';

type Props = TitleSubtitleProps & {
    onChange: (value: string) => void;
};
export const ConfigurationTextField = ({ title, subtitle, onChange }: Props) => {
    return (
        <Stack>
            <TitleSubtitle title={title} subtitle={subtitle} />
            <TextField fullWidth onChange={(event) => onChange(event.target.value)} />
        </Stack>
    );
};
