import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../useAuth';
import { LoadingProgress } from '../../components/shared/LoadingProgress';

export const Callback = () => {
    const { handleAuthentication } = useAuth();
    const location = useLocation();

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (/access_token|id_token|error/.test(location.hash)) {
                handleAuthentication();
            }
        }, 1000);
        return () => clearTimeout(timeout);
    }, [handleAuthentication, location]);

    return <LoadingProgress />;
};
