import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initLoadable, Loadable } from '../ApiData';
import { IUserSchema } from '../../api/ApiClientGenerated';

export interface IPutUpdateUserMessage {
    userName?: string | undefined;
    orgId?: string | undefined;
}

export interface UserState {
    user: Loadable<IUserSchema | undefined>;
}

const initialState: UserState = {
    user: initLoadable(undefined),
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getUserAction: (state) => {
            state.user.loading = true;
            state.user.loaded = false;
        },
        setUserAction: (state, action: PayloadAction<IUserSchema>) => {
            state.user.loaded = true;
            state.user.loading = false;
            state.user.value = action.payload;
        },
        updateUserAction: (state, action: PayloadAction<IPutUpdateUserMessage>) => {
            state.user.loading = true;
            state.user.loaded = false;
        },
    },
});

export const { getUserAction, setUserAction, updateUserAction } = userSlice.actions;

export const userReducer = userSlice.reducer;
