import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../components/pages/NotFound';
import { KnowledgeConnections } from '../components/pages/KnowledgeConnections';
import { GeneralSettings } from '../components/pages/GeneralSettings';
import { UserProfile } from '../components/pages/UserProfile';
import { LoginPage } from '../components/pages/LoginPage';
import { AuthRoute } from './AuthRoute';
import { Callback } from '../auth/components/Callback';
import { ActionConnections } from '../components/pages/ActionConnections';
import { ActionConfiguration } from '../components/pages/ActionConfiguration';
import { KnowledgeConfiguration } from '../components/pages/KnowledgeConfiguration';
import { AuthSuccess } from '../components/pages/AuthSuccess';

export class IdRoute {
    constructor(route: string) {
        this.route = route;
    }

    route = '';

    withId(id: number | string): string {
        const regex = new RegExp(':[^/]+');
        return this.route.replace(regex, String(id));
    }
}

export const AppRouter = () => {
    return (
        <Routes>
            <Route path={appRoutes.login} element={<LoginPage />} />
            <Route path={appRoutes.callback} element={<Callback />} />
            <Route path={appRoutes.authSuccess} element={<AuthSuccess />} />

            <Route
                path={appRoutes.knowledgeConnections.view}
                element={
                    <AuthRoute>
                        <KnowledgeConnections />
                    </AuthRoute>
                }
            />
            <Route
                path={appRoutes.knowledgeConnections.actions.configure.route}
                element={
                    <AuthRoute>
                        <KnowledgeConfiguration />
                    </AuthRoute>
                }
            />
            <Route
                path={appRoutes.actionConnections.view}
                element={
                    <AuthRoute>
                        <ActionConnections />
                    </AuthRoute>
                }
            />
            <Route
                path={appRoutes.actionConnections.actions.configure.route}
                element={
                    <AuthRoute>
                        <ActionConfiguration />
                    </AuthRoute>
                }
            />
            <Route
                path={appRoutes.generalSettings}
                element={
                    <AuthRoute>
                        <GeneralSettings />
                    </AuthRoute>
                }
            />
            <Route
                path={appRoutes.profile}
                element={
                    <AuthRoute>
                        <UserProfile />
                    </AuthRoute>
                }
            />
            <Route
                path={'*'}
                element={
                    <AuthRoute>
                        <NotFound />
                    </AuthRoute>
                }
            />
        </Routes>
    );
};

export const appRoutes = {
    login: '/login',
    callback: '/auth/callback',
    knowledgeConnections: {
        view: '/knowledge-connections',
        actions: {
            configure: {
                withId: (id: string) => `/knowledge-connections/configure/${id}`,
                route: '/knowledge-connections/configure/:id',
            } as IdRoute,
        },
    },
    actionConnections: {
        view: '/action-connections',
        actions: {
            configure: {
                withId: (id: string) => `/action-connections/configure/${id}`,
                route: '/action-connections/configure/:id',
            } as IdRoute,
        },
    },
    generalSettings: '/general-settings',
    profile: '/profile',
    authSuccess: '/auth-success',
};
