import { IConnectionDetailSchema, IPostAnswerListResponse, QuestionTypes } from '../../api/ApiClientGenerated';

class ConnectionSerializer {
    serializeConnectionDetailSchema = (connection: IConnectionDetailSchema | undefined) => {
        if (!connection) return;
        return {
            ...connection,
            questions: connection.questions?.map((question) => ({
                ...question,
            })),
        };
    };
    serializePostAnswerListResponseSchema = (answerList: IPostAnswerListResponse) => {
        return {
            ...answerList,
            answers: answerList.answers?.map((answer) => ({
                ...answer,
            })),
        };
    };
}

export const connectionSerializer = new ConnectionSerializer();
