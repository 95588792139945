import { Stack, TextField } from '@mui/material';
import React from 'react';
import { TitleSubtitle } from '../TitleSubtitle';
import { QuestionProps } from '../Types';

export const OpenTextQuestion = ({ question, answer, onChange }: QuestionProps) => {
    return (
        <Stack direction={'row'}>
            <Stack flex={1}>
                <TitleSubtitle title={question.label ?? ''} subtitle={question.subLabel} />
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <TextField
                        placeholder={answer?.value}
                        fullWidth
                        onChange={(event) => {
                            if (!question.id) return;
                            onChange({ id: answer?.id, questionId: question.id, newValue: event.target.value });
                        }}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};
