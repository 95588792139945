import { PageHeader } from './PageHeader';
import { HeaderContentIcon } from './HeaderContentIcon';
import React, { ReactElement, ReactNode } from 'react';
import { ConnectionInfoCard } from '../areas/configuration/ConnectionInfoCard';
import { useFetchConnection } from '../../hooks/useFetchConnection';

type Props = {
    header: string;
    subheader: string;
    IconComponent?: ReactElement;
    children?: ReactNode;
};

export const ConnectionConfiguration = ({ header, subheader, IconComponent, children }: Props) => {
    useFetchConnection();

    return (
        <>
            <PageHeader
                header={header}
                subheader={subheader}
                AdditionalHeaderContent={IconComponent ? () => <HeaderContentIcon icon={IconComponent} /> : undefined}
            />
            <ConnectionInfoCard />
            {children && children}
        </>
    );
};
