import React from 'react';
import { PageHeader } from '../shared/PageHeader';
import { HeaderContentIcon } from '../shared/HeaderContentIcon';
import { SettingsGearIcon } from '../../assets/SettingsGearIcon';
import { Box, Grid } from '@mui/material';
import { CustomizationSection } from '../areas/settings/customization/CustomizationSection';
import { LLMOptionsSection } from '../areas/settings/llmOptions/LLMOptionsSection';
import { StyledSaveButton } from '../shared/styledComponents/StyledButtons';
import { useSettings } from '../../hooks/useSettings';

export const GeneralSettings = () => {
    const { onSubmit } = useSettings();

    return (
        <>
            <Grid container flexDirection={'column'} spacing={2} paddingBottom={5}>
                <Grid item>
                    <PageHeader
                        header={'General Settings'}
                        subheader={'Custom icon, name, and more.'}
                        AdditionalHeaderContent={() => (
                            <HeaderContentIcon icon={<SettingsGearIcon width="75px" height="75px" />} />
                        )}
                    />
                </Grid>
                <Grid item>
                    <CustomizationSection />
                </Grid>
                <Box height={'60px'} />
                <Grid item>
                    <LLMOptionsSection />
                </Grid>
            </Grid>
            <Box alignSelf={'flex-end'} width={'200px'}>
                <StyledSaveButton fullWidth onClick={onSubmit}>
                    Save
                </StyledSaveButton>
            </Box>
        </>
    );
};
