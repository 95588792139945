import { Box, Button, Card, Typography } from '@mui/material';
import React, { FC, memo } from 'react';
import { ConnectionTypes, IConnectionListItemSchema } from '../../api/ApiClientGenerated';
import { AppConnectionLogo } from './AppConnectionLogo';
import { toReadableText } from '../../utils/stringUtils';
import { SectionHeader } from './SectionHeader';

export type Props = {
    connection: IConnectionListItemSchema;
    connectionType: ConnectionTypes;
    onClick: () => void;
};

// memoizing the result of the render of the AppCard component, so React will skip rendering
// the component and reuse the last rendered result unless the props change
export const AppCard: FC<Props> = memo(({ connection, connectionType, onClick }) => {
    const { name, description } = connection;

    return (
        <Card>
            <Box display={'flex'} flexDirection={'column'} height={'100%'} padding={2}>
                <Box mb="auto">
                    <Box display={'flex'} flexDirection={'row'} marginBottom={2} alignItems={'center'}>
                        <Box marginRight={2} width={'80px'} height={'80px'} textAlign={'center'} paddingTop={0.5}>
                            <AppConnectionLogo name={connection.name} />
                        </Box>
                        <Box>
                            <SectionHeader
                                title={connection.display_name}
                                subtitle={connectionType === ConnectionTypes.KNOWLEDGE ? 'Knowledge' : 'Action'}
                            />
                        </Box>
                    </Box>
                    <Typography fontSize={'small'}>{description}</Typography>
                </Box>
                <Box alignSelf={'flex-end'}>
                    <Button onClick={onClick}>Configure</Button>
                </Box>
            </Box>
        </Card>
    );
});
