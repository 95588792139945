export type Loadable<T> = {
    loading: boolean;
    loaded: boolean;
    value?: T;
};

export function initLoadable<T>(value: T): Loadable<T> {
    return {
        loading: false,
        loaded: false,
        value: value,
    } as Loadable<T>;
}
