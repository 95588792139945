import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { appColors } from '../../../themes/AppTheme';
import { LogoAndText } from './LogoAndText';
import { DisconnectionDialog } from './DisconnectionDialog';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ConnectionDialog } from './ConnectionDialog';
import { ConnectionStatusTypes } from '../../../api/ApiClientGenerated';
import { LoadingProgress } from '../../shared/LoadingProgress';
import { StyledToggleButton } from '../../shared/styledComponents/StyledButtons';
import { useAnswerUpdates } from '../../../hooks/useAnswerUpdates';
import { ConnectionCard } from '../../shared/styledComponents/StyledCards';
import { StyledGridContainer } from '../../shared/styledComponents/StyledGrids';

export const ConnectionInfoCard = () => {
    const { value, loaded, loading } = useSelector((state: RootState) => state.connection.connectionDetails);
    const [isConnectionDialogOpen, setIsConnectionDialogOpen] = useState(false);
    const [isDisconnectionDialogOpen, setIsDisconnectionDialogOpen] = useState(false);
    const isConnected = value?.connection_status_type === ConnectionStatusTypes.COMPLETE;
    const connectionButtonLabel = isConnected ? 'Disconnect' : 'Connect';
    const { indexQuestions } = useAnswerUpdates();

    if (!value) return <></>;

    const handleClickConnectionButton = () => {
        isConnected ? setIsDisconnectionDialogOpen(true) : setIsConnectionDialogOpen(true);
    };

    const handleCancel = () => {
        setIsConnectionDialogOpen(false);
        setIsDisconnectionDialogOpen(false);
    };

    if (loading && !loaded) return <LoadingProgress />;

    return (
        <>
            <ConnectionCard>
                <StyledGridContainer container spacing={1}>
                    <LogoAndText connection={value} />
                    <Grid xs={6} item container>
                        <Typography width={'500px'} variant={'body2'} color={appColors.chimpCharcoal}>
                            {value?.description}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <StyledToggleButton connected={isConnected} onClick={handleClickConnectionButton}>
                            {connectionButtonLabel}
                        </StyledToggleButton>
                    </Grid>
                </StyledGridContainer>
            </ConnectionCard>
            <ConnectionDialog
                connection={value}
                open={isConnectionDialogOpen}
                handleClose={handleCancel}
                questions={indexQuestions}
            />
            <DisconnectionDialog connection={value} open={isDisconnectionDialogOpen} handleClose={handleCancel} />
        </>
    );
};
