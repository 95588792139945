import { useAuth } from '../useAuth';
import { FormEvent, useRef, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { RememberAccountCheckbox } from './RememberAccountCheckbox';

type Props = {
    open: boolean;
    onClose: () => void;
};

export const SignUpDialog = ({ open, onClose }: Props) => {
    const { signupWithEmail } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const formRef = useRef<HTMLFormElement | null>(null);

    const handleSignup = (e: FormEvent) => {
        e.preventDefault();
        const isValid = formRef.current?.reportValidity();
        if (isValid) {
            signupWithEmail(email, password);
            setEmail('');
            setPassword('');
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                setEmail('');
                setPassword('');
                onClose();
            }}
            fullWidth
        >
            <DialogTitle>Create an account</DialogTitle>
            <DialogContent>
                <form ref={formRef} onSubmit={handleSignup}>
                    <Stack spacing={2}>
                        <TextField
                            label={'Email'}
                            fullWidth
                            variant={'filled'}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            label={'Password'}
                            type={'password'}
                            fullWidth
                            variant={'filled'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <RememberAccountCheckbox />
                        <Button type={'submit'} variant={'contained'}>
                            Create account
                        </Button>
                    </Stack>
                </form>
            </DialogContent>
        </Dialog>
    );
};
