import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useDispatch } from 'react-redux';
import { handleDisconnectAction } from '../../../redux/slices/connectionSlice';
import { DialogProps } from '../../shared/Types';
import { StyledToggleButton } from '../../shared/styledComponents/StyledButtons';

export const DisconnectionDialog = ({ connection, open, handleClose }: DialogProps) => {
    const dispatch = useDispatch();

    const handleDisconnect = () => {
        connection.id && dispatch(handleDisconnectAction(connection.id));
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{'Disconnect Knowledge Connection'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to disconnect from your {connection.name} instance? You will lose access to
                    any data from this source.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <StyledToggleButton onClick={handleDisconnect} autoFocus>
                    Disconnect
                </StyledToggleButton>
            </DialogActions>
        </Dialog>
    );
};
