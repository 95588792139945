export class TokenSingleton {
    private static instance: TokenSingleton;
    private AccessToken: string;

    private constructor() {
        this.AccessToken = '';
    }

    private static getInstance(): TokenSingleton {
        if (!TokenSingleton.instance) {
            TokenSingleton.instance = new TokenSingleton();
        }

        return TokenSingleton.instance;
    }

    public static getAccessToken() {
        const instance = TokenSingleton.getInstance();
        return instance.AccessToken;
    }

    public static setAccessToken(token: string) {
        const instance = TokenSingleton.getInstance();
        instance.AccessToken = token;
    }
}
