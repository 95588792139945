import { Box, Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { interfaceService } from '../services/InterfaceService';
import { setDefaultErrorNotification } from '../redux/slices/globalSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const AddToSlackButton = () => {
    const dispatch = useDispatch();

    const getSlackUrl = async () => {
        try {
            const response = await interfaceService.initiateSlackAuth();
            if (response.status === 200) {
                window.location.replace(response.result);
            } else {
                dispatch(
                    setDefaultErrorNotification({
                        debugMessage: `Get slack url failed with status code ${response.status}`,
                    }),
                );
            }
        } catch (err) {
            const error = err as Error;
            dispatch(setDefaultErrorNotification({ debugMessage: error.message }));
        }
    };

    return (
        <Stack alignItems={'center'} mt={2} justifyContent={'center'}>
            <Button
                onClick={getSlackUrl}
                sx={{
                    alignItems: 'center',
                    border: '1px solid',
                    borderRadius: 4,
                    display: 'inline-flex',
                    fontSize: 16,
                    fontWeight: 600,
                    height: 48,
                    justifyContent: 'center',
                    width: 236,
                    fontFamily: 'Lato, sans-serif',
                    textTransform: 'none',
                }}
            >
                <Stack alignItems={'center'} marginRight={'12px'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 122.8 122.8">
                        <path
                            d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                            fill="#e01e5a"
                        ></path>
                        <path
                            d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                            fill="#36c5f0"
                        ></path>
                        <path
                            d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                            fill="#2eb67d"
                        ></path>
                        <path
                            d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                            fill="#ecb22e"
                        ></path>
                    </svg>
                </Stack>
                Add to Slack
            </Button>
        </Stack>
    );
};
