import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import appTheme from './themes/AppTheme';
import { ToastNotification } from './components/shared/ToastNotification';
import { Layout } from './components/shared/Layout';
import { AppRouter } from './router/AppRouter';

function App() {
    return (
        <ThemeProvider theme={appTheme}>
            <CssBaseline />
            <Layout>
                <AppRouter />
            </Layout>
            <ToastNotification />
        </ThemeProvider>
    );
}

export default App;
