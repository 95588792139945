import React from 'react';
import { SwitchQuestion } from '../../shared/questionTypes/SwitchQuestion';
import { OpenTextQuestion } from '../../shared/questionTypes/OpenTextQuestion';
import { Question, QuestionTypes } from '../../../api/ApiClientGenerated';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

type Props = {
    question: Question;
    onChange: ({ id, questionId, newValue }: { id?: number; questionId: number; newValue: string }) => void;
};

export const QuestionFactory = ({ question, onChange }: Props) => {
    const { value } = useSelector((state: RootState) => state.connection.answerConfiguration) || {};
    const currentAnswer = value?.answers?.find((answer) => answer?.question_id === question.id);

    const renderQuestion = (question: Question) => {
        switch (question.type) {
            case QuestionTypes.SWITCH:
                return <SwitchQuestion question={question} answer={currentAnswer} onChange={onChange} />;
            case QuestionTypes.TEXT:
                return <OpenTextQuestion question={question} answer={currentAnswer} onChange={onChange} />;
            default:
                return <></>;
        }
    };

    return question && question.id !== undefined ? renderQuestion(question) : <></>;
};
