import { all, put, select, takeLatest } from 'redux-saga/effects';
import { callApi } from './callApi';
import { settingsService } from '../../services/SettingsService';
import {
    getSettingsAction,
    ISettings,
    setCustomizationSettingsAction,
    setLlmSettingsAction,
    submitSettingsAction,
} from '../slices/settingsSlice';
import { RootState } from '../store';
import { setSuccessNotificationAction } from '../slices/globalSlice';

function* getSettings() {
    const settings: ISettings = yield callApi(settingsService.getSettings);
    yield put(setLlmSettingsAction(settings.llmOptions));
    yield put(setCustomizationSettingsAction(settings.customization));
}

function* submitSettings() {
    const { llmOptions, customization } = yield select((state: RootState) => state.settings);

    yield callApi(settingsService.updateSettings, llmOptions, customization);
    yield put(setSuccessNotificationAction({ message: 'Successfully saved settings' }));
}

export function* settingsSagas() {
    yield all([takeLatest(getSettingsAction.type, getSettings), takeLatest(submitSettingsAction.type, submitSettings)]);
}
